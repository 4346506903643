import React, { useState, useEffect } from 'react';
import { useUser } from '../context/user'; // Make sure to import the useUser hook
import {SubscriptionPlan} from '../models/subscription-plans.model';
const Profile = () => {
  const { user } = useUser(); // Get user data from context
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');

  useEffect(() => {
    // Check if user data is available and set username and email
    if (user) {
      setUsername(user.username);
      setEmail(user.email);
    }
  }, [user]);

  const handleAddEmail = () => {
    if (emailInput && emails.length < 5) {
      setEmails([...emails, emailInput]);
      setEmailInput(''); // Clear input field
    }
  };

  return (
    <>
      <div className="profilePage">
        <div className="backgroundImg">
          <img src="/assets/images/HeroImage.png" alt="" />
        </div>
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-8">
              <div className="formBox">
                <h2>Profile Details</h2>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="label">Username</div>
                    <div className="boxInput">
                      {username} {/* Display the username from user data */}
                      <img src="/assets/images/account_circle.png" alt="Account" />
                    </div>
                    <div className="label">Email</div>
                    <div className="boxInput">
                      {email} {/* Display the email from user data */}
                      <img src="/assets/images/mail.png" alt="Email" />
                    </div>
                    <div className="label">Password</div>
                    <div className="boxInput">
                      *********
                      <img src="/assets/images/lock.png" alt="Password" />
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex justify-content-center align-items-center">
                    <img src="/assets/images/avatar.png" alt="Avatar" />
                  </div>
                </div>
              </div>
            </div>
           {
            user?.subscriptionPlan === SubscriptionPlan.PRO_PLAN &&
             <div className="col-lg-3">
             <div className="formBox">
               <h2>Add Emails</h2>
               <div>
                 <ul>
                   {emails.map((email, index) => (
                     <li key={index} className='text-white'>{email}</li>
                   ))}
                 </ul>
               </div>
               <input
                 type="email"
                 value={emailInput}
                 onChange={(e) => setEmailInput(e.target.value)}
                 placeholder="Enter email"
                 className='text-white'
               />
               <button onClick={handleAddEmail} disabled={emails.length >= 5}>
                 Add
               </button>
             </div>
           </div>
           }
            <div className="col-lg-8">
              <div className="formBox">
                <h2>Helmet Collection</h2>
                <div className="row justify-content-center">
                  <div className="col-lg-2">
                    <img src="/assets/images/helmet1.png" alt="Helmet 1" />
                    <p className="helmetDesc">Country Road</p>
                  </div>
                  <div className="col-lg-2">
                    <img src="/assets/images/helmet2.png" alt="Helmet 2" />
                    <p className="helmetDesc">Classic</p>
                  </div>
                  <div className="col-lg-2">
                    <img src="/assets/images/helmet3.png" alt="Helmet 3" />
                    <p className="helmetDesc">Speed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
