import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


export const handleSuccess = (message) => {
    toast.success(message, 
    {
        position: "bottom-right"
    });
}
export const handleError = (message) => {
    toast.error(message, 
    {
        position: "bottom-right"
    });
}

export const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'authorization': `${localStorage.getItem('jwtToken')}`
      }
};

export const HandleErrorResponse = (response,navigate, setUser) => {
    // console.log(response);
    // const navigate = useNavigate();
    if(response.status === 403 || response.status === 401){
        setUser(null);
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('username');
        navigate('/login');
    }
};