import React, { createContext, useContext, useState, useEffect } from "react";
import { getHeaders } from "../utils";
import { HandleErrorResponse } from "../utils";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loadUserData = async () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      try {
        const response = await fetch(
          `https://backend.afxracing.com/auth/user`,
          {
            method: "GET",
            headers: getHeaders(),
          }
        );
        HandleErrorResponse(response, navigate, setUser);
        if (response.ok) {
          const res = await response.json();
          setUser(res?.data?.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
        setUser(null);
      }
    } else {
      console.log("No token found, setting user to null");
      setUser(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading, setUser, loadUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
