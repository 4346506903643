import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Login from './pages/login';
import Signup from './pages/signup';
import Home from './pages/home';
import PaymentForm from './pages/paymentForm';
import Profile from './pages/profile';
import { UserProvider } from './context/user';
import ProtectedRoute from './pages/ProtectedRoute';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Navbar from './pages/navbar';
import { ToastContainer } from "react-toastify";

function App() {
    return (
        <UserProvider>
            <Navbar />
            <div className="App">
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    {/* Protected Routes */}
                    <Route
                        path="/home"
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/payment"
                        element={
                            <ProtectedRoute>
                                <PaymentForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <ProtectedRoute>
                                <Profile />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </div>
            <ToastContainer />
        </UserProvider>
    );
}

export default App;
