import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubscriptionPlan } from "../models/subscription-plans.model";
import { useUser } from "../context/user";
import { HandleErrorResponse } from "../utils";
import { ToastContainer } from "react-toastify";
import { handleError, handleSuccess } from "../utils";
const PaymentForm = () => {
  const { loadUserData, setUser } = useUser();
  const [selectedMethod, setSelectedMethod] = useState("credit");
  const [subscriptionType, setSubscriptionType] = useState("");
  const cardNumber = useRef(null);
  const expiryDate = useRef(null);
  const cvv = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [searchParams] = useSearchParams();
  const [plan, setPlan] = useState("");
  const [promoCode, setPromoCode] = useState("");

  useEffect(() => {
    const plan = searchParams.get("plan");
    if (
      plan !== SubscriptionPlan.FREE_PLAN &&
      plan !== SubscriptionPlan.BASIC_PLAN &&
      plan !== SubscriptionPlan.PRO_PLAN
    ) {
      navigate("/home");
      return;
    }
    setPlan(plan);
  }, [navigate, searchParams]); // Add 'navigate' and 'searchParams' to the dependency array

  const handleInputCardNumber = (e) => {
    let value = e.target.value.replace(/\s/g, "").slice(0, 16); // Remove spaces and limit to 16 digits
    value = value.replace(/(\d{4})(?=\d)/g, "$1 "); // Add space after every 4 digits
    cardNumber.current.value = value;
  };

  const handleInputExpiryDate = (e) => {
    let value = e.target.value.replace(/\D/g, "").slice(0, 4);
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    expiryDate.current.value = value;
  };

  const formatDateTime = () => {
    const currentDate = new Date();

    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    return { formattedTime, formattedDate };
  };

  const handlePromoCode = async () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      return navigate("/login");
    }

    if (!promoCode) {
      setErrorMessage("Please Enter Promo Code first!");
      return;
    }

    try {
      const url = `https://backend.afxracing.com/auth/subscription`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `${localStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({
          plan: plan,
          promoCode: promoCode?.toUpperCase(),
        }),
      });

      const data = await response.json();
      HandleErrorResponse(response, navigate, setUser);
      if (response.ok) {
        await loadUserData();
        handleSuccess(response.message || "Subscription created successfully");
        navigate("/home");
      } else {
        handleError(data.message || "Failed to create subscription");
        throw new Error(data.message || "Failed to create subscription");
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  const handleSubs = async () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      return navigate("/login");
    }
    setErrorMessage("");

    const cardNum = cardNumber.current.value.replace(/\s/g, "");
    const expDate = expiryDate.current.value;
    const cvvNum = cvv.current.value;

    // Validation for card details
    if (!cardNum || !expDate || !cvvNum) {
      return setErrorMessage("All fields are required.");
    }

    if (!/^\d{16}$/.test(cardNum)) {
      return setErrorMessage("Card number must be 16 digits.");
    }

    if (!/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(expDate)) {
      return setErrorMessage("Expiration date must be in MM/YY format.");
    }

    if (!/^\d{3}$/.test(cvvNum)) {
      return setErrorMessage("CVC must be 3 digits.");
    }

    // Validation for subscription type
    if (!subscriptionType) {
      return setErrorMessage("Please select a subscription type.");
    }

    const [month, year] = expDate.split("/");
    const formattedExpirationDate = `20${year}-${month}`;
    try {
      const url = `https://backend.afxracing.com/auth/subscription`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `${localStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({
          cardNumber: cardNum,
          expirationDate: formattedExpirationDate,
          cvv: cvvNum,
          plan: plan,
          subscriptionType: subscriptionType,
        }),
      });

      const data = await response.json();
      HandleErrorResponse(response, navigate, setUser);
      if (response.ok) {
        console.log("Subscription created:", data);

        // Get formatted time and date
        const { formattedTime, formattedDate } = formatDateTime();
        setTime(formattedTime);
        setDate(formattedDate);

        // Toggle visibility
        document.querySelector(".paymentStart").classList.add("d-none");
        document.querySelector(".paymentFinish").classList.remove("d-none");
      } else {
        throw new Error(data.message || "Failed to create subscription");
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.log("Error creating subscription:", error);
    }
  };

  return (
    <>
      <div className="paymentPage">
        <div className="backgroundImg">
          <img src="/assets/images/HeroImage.png" alt="" />
        </div>
        <div className="container paymentStart">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="form-box">
                <div className="row justify-content-center">
                  <div className="col-9">
                    <h1>Payment</h1>
                    {errorMessage && <p className="error">{errorMessage}</p>}

                    {/* Payment method selection */}

                    {plan === SubscriptionPlan.FREE_PLAN ? (
                      <React.Fragment>
                        <div className="input-group">
                          <label>Promo Code</label>
                          <input
                            type="text"
                            placeholder="Enter Promo Code"
                            value={promoCode}
                            onChange={(e) => {
                              const upperCasePromoCode =
                                e.target.value.toUpperCase();
                              setPromoCode(upperCasePromoCode);
                            }}
                          />
                        </div>

                        <div className="col-12">
                          <div className="pay-button" onClick={handlePromoCode}>
                            SUBSCRIBE
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="paymentMethod">
                          <div
                            className={`method ${
                              selectedMethod === "credit" ? "selected" : ""
                            }`}
                            onClick={() => setSelectedMethod("credit")}
                          >
                            <img src="/assets/images/credit-card.svg" alt="" />
                            <span>Credit or Debit</span>
                          </div>
                          <div
                            className={`method ${
                              selectedMethod === "bank" ? "selected" : ""
                            }`}
                            onClick={() => setSelectedMethod("bank")}
                          >
                            <img
                              src="/assets/images/bank-transfer.svg"
                              alt=""
                            />
                            <span>Direct Bank Transfer</span>
                          </div>
                          <div
                            className={`method ${
                              selectedMethod === "apple" ? "selected" : ""
                            }`}
                            onClick={() => setSelectedMethod("apple")}
                          >
                            <img src="/assets/images/apple-pay.svg" alt="" />
                            <span>Apple Pay</span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="input-group">
                              <label>Card number</label>
                              <input
                                type="text"
                                placeholder="1234 1234 1234 1234"
                                maxLength="19"
                                ref={cardNumber}
                                onInput={handleInputCardNumber}
                              />
                              <div className="card-icons">
                                <img
                                  src="/assets/images/MasterCard.png"
                                  alt="Mastercard"
                                />
                                <img src="/assets/images/Visa.png" alt="Visa" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-group">
                              <label>Expiration</label>
                              <input
                                type="text"
                                placeholder="MM / YY"
                                maxLength="5"
                                ref={expiryDate}
                                onInput={handleInputExpiryDate}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-group">
                              <label>CVC</label>
                              <input
                                type="text"
                                placeholder="CVC"
                                maxLength="3"
                                ref={cvv}
                              />
                            </div>
                          </div>
                          {/* Subscription Type (Annual/Monthly) */}
                          <div className="subscriptionType">
                            <label>
                              <input
                                type="radio"
                                value="monthly"
                                checked={subscriptionType === "monthly"}
                                onChange={() => setSubscriptionType("monthly")}
                              />
                              Monthly
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="annual"
                                checked={subscriptionType === "annual"}
                                onChange={() => setSubscriptionType("annual")}
                              />
                              Annual
                            </label>
                          </div>
                          <div className="col-12">
                            <div className="pay-button" onClick={handleSubs}>
                              PAY
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container paymentFinish d-none">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="form-box text-center">
                <h2>Subscription Successful!</h2>
                <p>
                  Payment was processed on {date} at {time}.
                </p>
                <button
                  onClick={() => {
                    navigate("/home");
                  }}
                  className="button"
                >
                  Return Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
