import React from 'react'
import { Link } from "react-router-dom";
const Home = () => {

  return (
    <>
      <div className="homePage">
        <div className="backgroundImg">
          <img src="/assets/images/HeroImage.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>
                Get Ready to
                <br />
                Play, Explore,
                <br />
                Conquer
              </h1>
              <p>
                Embark on your gaming journey of discovery and excitement right
                here, where the world of immersive gameplay.
              </p>
              <div className="buttonsHome">
                <div className="btn download">Download App</div>
                <div className="btn subscription">View Subscription</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing" id='pricingSection'>
        <div className="container">
          <h2>
            Discover the Most Popular <br />
            Subscription Packages
          </h2>
          <div className="row">
            <div className="col-lg-4">
              <div className="HomeCard free">
                <div className="imgHover">
                  <img src="/assets/images/package-free.png" alt="" />
                </div>
                <div className="title">
                  Free
                </div>
                <div className="price">
                  PROMO CODE
                </div>
                <ul>
                  <li>
                    7 Days free trial
                  </li>
                  <li>
                  2P RACE only
                  </li>
                  <li>
                  NO RACE STATS 
                  </li>
                  <li>
                  UNLIMITED LAPS
                  </li>
                  <li>
                  UNLIMITED CARS
                  </li>
                </ul>
                <Link to="/payment?plan=Free">
                <div className="activateButton">
                  <p className="activate">Activate</p>
                  <p className="subscribes">
                    2 M 
                    <span>Subscribed</span>
                  </p>
                </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="HomeCard basic ">
                <div className="imgHover">
                  <img src="/assets/images/package-basic.png" alt="" />
                </div>
                <div className="title">
                  Single
                </div>
                <div className="price">
                  $29.99/Annual OR $3.99/Monthly
                </div>
                <ul>
                  <li>
                    7 Days free trial
                  </li>
                  <li>
                  2P RACE only
                  </li>
                  <li>
                  RACE STATS 
                  </li>
                  <li>
                  UNLIMITED LAPS
                  </li>
                  <li>
                  UNLIMITED CARS
                  </li>
                </ul>
                <Link to="/payment?plan=Basic">
                <div className="activateButton">
                  <p className="activate">Activate</p>
                  <p className="subscribes">
                    2 M 
                    <span>Subscribed</span>
                  </p>
                </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="HomeCard pro">
                <div className="imgHover">
                  <img src="/assets/images/package-pro.png" alt="" />
                </div>
                <div className="title">
                  Multi-User
                </div>
                <div className="price">
                $39.99/Annual OR $6.99/Mnthly
                </div>
                <ul>
                  <li>
                    6 Months Trial
                  </li>
                  <li>
                  4P RACE
                  </li>
                  <li>
                  RACE STATS 
                  </li>
                  <li>
                  UNLIMITED LAPS
                  </li>
                  <li>
                  UNLIMITED CARS
                  </li>
                </ul>
                <Link to="/payment?plan=Pro">
                <div className="activateButton">
                  <p className="activate">Activate</p>
                  <p className="subscribes">
                    2 M 
                    <span>Subscribed</span>
                  </p>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
}

export default Home