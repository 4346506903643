import React from 'react'
import { Link } from 'react-router-dom'

const footer = () => {
  return (
    <>
        <div className="footerSection">
            <ul>
                <li>
                    <Link to="/">
                        ig
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        tw
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        yt
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        ln
                    </Link>
                </li>
            </ul>
            <p>
            © 2024 AFX. All rights reserved.
            </p>
        </div>
    </>
  )
}

export default footer