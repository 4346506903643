import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../context/user';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const { user } = useUser(); 

  // useEffect(() => {
  //   // Function to get the username and set the login state
  //   const checkLoginStatus = () => {
  //     const jwtToken = localStorage.getItem('jwtToken'); // Get the JWT token
  //     const storedUsername = localStorage.getItem('username'); // Get the username from localStorage
  //     if (jwtToken && storedUsername) {
  //       setUsername(storedUsername); // Set the username from localStorage
  //       setIsLoggedIn(true); // User is logged in
  //     } else {
  //       setIsLoggedIn(false); // No token or username found, user is not logged in
  //     }
  //   };
  
  //   // Check login status when component mounts
  //   checkLoginStatus();
  
  //   // Listen for the custom login/logout event
  //   window.addEventListener('loginStatusChanged', checkLoginStatus);
  
  //   // Cleanup event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('loginStatusChanged', checkLoginStatus);
  //   };
  // }, []);
  
  useEffect(() => {
    if (user) {
      setUsername(user.username);
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
    }
  }, [user]);

  const handleLogout = () => {
    // Clear token and username from localStorage
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    setUsername('');
  };

  return (
    <div className="navbar">
      <div className="container">
        <Link to="/">
          <img src="/assets/images/MainLogo.png" alt="Main Logo" />
        </Link>
        {isLoggedIn ? (
          <ul className="LoggedIn">
            <li>
              <Link to="/home#pricingSection">Pricing</Link>
            </li>
            <li>
              <Link to="/profile">
              {username}
              </Link>
            </li>
            <li>
              <Link to="/login" onClick={handleLogout}>Logout</Link>
            </li>
          </ul>
        ) : (
          <ul className="notLoggedIn">
            <li>
              <Link to="/home#pricingSection">Pricing</Link>
            </li>
            <li>
              <Link to="/login">Sign in</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Navbar;
