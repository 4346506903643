import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { handleError, handleSuccess } from "../utils";

const Signup = () => {
  const [SignupInfo, setSignupInfo] = useState({
    username: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    const copySignupInfo = { ...SignupInfo };
    copySignupInfo[name] = value;
    setSignupInfo(copySignupInfo);
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    const { username, email, password } = SignupInfo;
    if (!username || !email || !password) {
      return handleError("All fields are required");
    }
    try {
      const url = `https://backend.afxracing.com/auth/signup`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(SignupInfo),
      });
      const data = await response.json();
      const { success, message, error } = data;
      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else if (error) {
        const details = error?.details[0].message;
        handleError(details);
      }
      console.log(data);
    } catch (error) {
      handleError(error.message);
    }
  };
  return (
    <>
      <div className="signupPage">
        <div className="backgroundImg">
          <img src="/assets/images/HeroImage.png" alt="" />
        </div>
        <div className="container">
          <div class="row justify-content-center">
            <div className="col-lg-6">
              <div className="form-box">
                <h1>Sign Up</h1>
                <form onSubmit={handleSignup}>
                  <div className="formInput">
                    <input
                      type="text"
                      onChange={handleChange}
                      name="username"
                      id="username"
                      placeholder="Username"
                      value={SignupInfo.username}
                    />
                  </div>
                  <div className="formInput">
                    <input
                      onChange={handleChange}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      value={SignupInfo.email}
                    />
                  </div>
                  <div className="formInput">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={SignupInfo.password}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="alreadyAccount">
                      <Link to="/login">Sign In</Link>
                      <p>Already have an account?</p>
                    </div>
                    <button className="submitButton" type="submit">
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
