import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { handleError, handleSuccess } from "../utils";
import { useUser } from "../context/user";

const Login = () => {
  const { loadUserData } = useUser();
  const [LoginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...LoginInfo, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = LoginInfo;

    if (!username || !password) {
      return handleError("Both fields are required");
    }

    try {
      const url = `https://backend.afxracing.com/auth/login`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return handleError(errorData.message || "Something went wrong");
      }

      const data = await response.json();
      const { success, message, jwtToken, error } = data;

      if (success) {
        handleSuccess(message);
        localStorage.setItem("jwtToken", jwtToken);
        localStorage.setItem("username", username);
        window.dispatchEvent(new Event("loginStatusChanged"));
        await loadUserData();
        navigate("/home");
        navigate("/");
      } else if (error) {
        const details = error?.details[0]?.message || error.message;
        handleError(details);
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  return (
    <>
      <div className="loginPage">
        <div className="backgroundImg">
          <img src="/assets/images/HeroImage.png" alt="" />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="form-box">
                <h1>Login</h1>
                <form onSubmit={handleLogin}>
                  <div className="formInput">
                    <input
                      type="text"
                      onChange={handleChange}
                      name="username"
                      id="username"
                      placeholder="Username"
                      value={LoginInfo.username}
                      required // Added required for better UX
                    />
                  </div>
                  <div className="formInput">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={LoginInfo.password}
                      required // Added required for better UX
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="alreadyAccount">
                      <Link to="/signup">Sign Up</Link>
                      <p>Don't have an account?</p>
                    </div>
                    <button className="submitButton" type="submit">
                      Login
                    </button>
                  </div>
                  {/* <ToastContainer /> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
